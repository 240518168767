<template>
  <div id="verificationDetails">
    <h1 v-if="isLoading">Loading...</h1>
    <verification-details-sidebar :ver="ver" :show-details.sync="showDetails" />
    <div v-if="isLoading == false && ver">
      <b-row>
        <b-col md="4">
          <b-card header="Verifications Details" no-body header-class="card-header-info">
            <ul class="list-info">
              <li><span>Verification ID</span>{{ ver._id }}</li>
              <li><span>Requested Date</span>{{ moment(ver.createdAt).format("YYYY-MM-DD") }}</li>
              <li>
                <span>Responding Date</span>{{ moment(ver.responsedAt).format("YYYY-MM-DD") }}
              </li>
              <li>
                <span>Current Status</span
                ><b-badge
                  :variant="`light-${
                    statusOptions.find((x) => x.value == ver.currentStatus).color
                  }`"
                  >{{ ver.currentStatus }}</b-badge
                >
              </li>
            </ul>
          </b-card>
          <b-card
            header="User Information"
            no-body
            header-class="card-header-info"
            v-if="ver.userId"
          >
            <ul class="list-info">
              <li>
                <span>Signup Date</span>
                {{ moment(ver.userId.createdAt).format("YYYY-MM-DD") }}
              </li>
              <li>
                <span>Username</span
                ><b-link target="_blank" :href="`/profile/${ver.userId.username}`">{{
                  ver.userId.username
                }}</b-link>
              </li>
              <li>
                <span>Email Address</span
                ><b-link target="_blank" :href="`mailto:${ver.userId.email}`">{{
                  ver.userId.email
                }}</b-link>
              </li>
              <li>
                <span>User Type: </span>
                {{ ver.userId.userType }}
              </li>
              <li>
                <span>Profile Type Data: </span>

                <b-link :href="`/profile/${ver.userId.username}`" target="_blank"
                  >Click here to View</b-link
                >
              </li>
            </ul>
          </b-card>
          <b-card
            header="Assigned Partner Information"
            no-body
            header-class="card-header-info"
            v-if="ver.partnerId && role != 'Partner'"
          >
            <div v-if="!ver.partnerId.username" class="text-center text-danger p-2 border-top">
              Sorry! partner is not assigned to this verification yet
            </div>
            <ul v-if="ver.partnerId.username" class="list-info">
              <li>
                <span>Username</span
                ><b-link target="_blank" :href="`/profile/${ver.partnerId.username}`">{{
                  ver.partnerId.username
                }}</b-link>
              </li>
            </ul>
          </b-card>
        </b-col>
        <b-col md="8">
          <b-card header="Verification Process History" no-body header-class="card-header-info">
            <b-table :items="ver.statusHistory" :fields="historyTableColumns">
              <template #cell(performedBy)="data">
                <a target="_blank" :href="`/profile/${data.item.performedBy.username}`">{{
                  data.item.performedBy.username
                }}</a>
                <br /><span class="text-muted">{{ data.item.performedBy.userType }}</span>
              </template>
              <template #cell(status)="data">
                <b-badge
                  :variant="`light-${statusOptions.find((x) => data.item.status == x.value).color}`"
                  >{{ data.item.status }}</b-badge
                >
              </template>
            </b-table>
          </b-card>

          <b-card title="Post New Updates">
            <b-row>
              <b-col md="12">
                <div
                  v-if="message.text && message.type"
                  :class="{
                    alert: true,
                    'p-1': true,
                    'alert-success': message.type == 'success',
                    'alert-danger': message.type == 'error',
                  }"
                  v-text="message.text"
                ></div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="mb-2">
                <label for="comments">Comments</label>
                <b-form-input
                  placeholder="Please mention reason for status change"
                  type="text"
                  v-model="comments"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <label for="newStatus">New Status</label>
                <v-select v-model="newStatus" :options="statusOptions" />
              </b-col>
              <b-col md="6">
                <label for="newStatus">&nbsp;</label>
                <b-button variant="primary" block :disabled="isSaving" @click="saveStatus"
                  >{{ isSaving ? "Please wait.." : "Submit" }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTable,
  BFormInput,
  BLink,
  BButton,
  BBadge,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import useVerification from "../useVerifications";
import VerificationDetailsSidebar from "./VerificationDetailsSidebar";
import store from "@/store";
export default {
  components: {
    BRow,
    BCard,
    BBadge,
    BCol,
    BTable,
    BFormInput,
    BLink,
    BButton,
    BCardBody,
    vSelect,
    VerificationDetailsSidebar,
  },
  data() {
    return {
      ver: {},
      isLoading: false,
      isSaving: false,
      message: {
        type: "",
        text: "",
      },
      newStatus: "",
      comments: "",
      showDetails: false,
      role: null,
      historyTableColumns: [
        {
          key: "performedBy",
          label: "Performed By",
          formatter: (value) => {
            return value.username;
          },
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "createdAt",
          label: "Date / Time",
          formatter: (value) => {
            return moment(value).format("YYYY-MM-DD");
          },
        },
        {
          key: "comments",
          label: "Comments",
        },
      ],
    };
  },
  methods: {
    moment: function (input) {
      return moment(input);
    },
    showSidebar() {
      this.showDetails = true;
    },
    async getVerificationData() {
      const verificationId = this.$route.params.id;
      // check if verification id is not provided
      if (verificationId == "") {
        this.$router.push("/verifications");
      }
      try {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("verificationId", verificationId);
        const { data } = await axios.post("/verification", formData);
        this.ver = data.data;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async saveStatus() {
      this.message = {
        type: "",
        text: "",
      };
      if (this.comments == "" || this.newStatus == "") {
        this.message = {
          type: "error",
          text: "Please fill all fields",
        };
        return;
      }

      try {
        this.isSaving = true;
        const formData = new FormData();
        formData.append("verificationId", this.ver._id);
        formData.append("status", this.newStatus.value);
        formData.append("comments", this.comments);
        const { data } = await axios.post("/verification/updateHistory", formData);
        this.ver = data.data;
        this.isSaving = false;
        this.comments = "";
        this.message = {
          type: "success",
          text: "Status updated successfully",
        };
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getVerificationData();
    this.role = store.getters["auth/getRole"];
  },
  setup() {
    const { statusOptions } = useVerification();

    return {
      statusOptions,
    };
  },
};
</script>
