<template>
  <b-sidebar
    :visible="showDetails"
    bg-variant="white"
    shadow
    right
    backdrop
    no-header
    sidebar-class="sidebar-lg"
    @change2="(val) => $emit('update:showDetails', val)"
    @change="handleChangeSidebar"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">User Details</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- Start advisor -->
      <div v-if="ver.userType == 'advisor'" class="p-1">
        <b-card header="Advisor Details" no-body header-class="card-header-info">
          <ul class="list-info list-info-less">
            <li><span>Full Name</span> {{ ver.userTypeData.advisor.fullName }}</li>
            <li><span>Designation</span> {{ ver.userTypeData.advisor.designation }}</li>

            <li>
              <span>Phone</span
              >{{
                `${ver.userTypeData.advisor.advisorPhone.code} ${ver.userTypeData.advisor.advisorPhone.phoneNumber}`
              }}
            </li>
            <li><span>Email Address</span> {{ ver.userTypeData.advisor.emailAddress }}</li>
            <li><span>Gender</span> {{ ver.userTypeData.advisor.gender }}</li>
            <li><span>Full</span> {{ ver.userTypeData.advisor.fullName }}</li>
            <li class="p-1 d-block">
              <strong>Addresse(s)</strong>
              <div
                v-for="(address, addressIndex) in ver.userTypeData.advisor.address"
                :key="`address${addressIndex}`"
              >
                {{ address.name }}
              </div>
            </li>
            <li class="p-1 d-block">
              <strong>Industries(s)</strong>
              <div
                v-for="(industry, indIndex) in ver.userTypeData.advisor.industries"
                :key="`ind${indIndex}`"
              >
                {{ industry.name }}
              </div>
            </li>
            <li class="p-1 d-block">
              <strong>Skill(s)</strong>
              <div
                v-for="(skill, skillIndex) in ver.userTypeData.advisor.skills"
                :key="`ind${skillIndex}`"
              >
                {{ skill.name }}
              </div>
            </li>
            <li class="p-1 d-block">
              <strong>Experience(s)</strong>
              <div
                v-for="(exp, expIndex) in ver.userTypeData.advisor.experiences"
                :key="`exp${expIndex}`"
              >
                <strong>{{ exp.company }}</strong> in {{ exp.location.name }}
              </div>
            </li>
            <li class="p-1 d-block">
              <strong>Languages(s)</strong>
              <div
                v-for="(lang, langIndex) in ver.userTypeData.advisor.languages"
                :key="`exp${langIndex}`"
              >
                <strong>{{ lang.name }}</strong> - {{ lang.level }}
              </div>
            </li>
            <li class="p-1 d-block">
              <strong>Files(s)</strong>
              <div
                v-for="(file, fileIndex) in ver.userTypeData.advisor.files"
                :key="`exp${fileIndex}`"
              >
                <a :href="file.fileUrl" target="_blank">{{ file.name }}</a>
              </div>
            </li>
          </ul>
        </b-card>
      </div>
      <!-- End advisor -->

      <!-- Start Investor -->
      <div v-if="ver.userType == 'investor'" class="p-1">
        <b-card header="Investor Details" no-body header-class="card-header-info">
          <ul class="list-info list-info-less">
            <li><span>Short Name</span>{{ ver.userTypeData.investor.shortName }}</li>
            <li><span>Legal Name</span>{{ ver.userTypeData.investor.legalName }}</li>
            <li><span>Company Name</span>{{ ver.userTypeData.investor.companyName }}</li>
            <li>
              <span>Investment Size:</span
              >{{
                `${ver.userTypeData.investor.investmentSize.min} - ${ver.userTypeData.investor.investmentSize.max}`
              }}
            </li>
            <li class="d-block p-1">
              <strong>Business Factor</strong><br />{{ ver.userTypeData.investor.businessFactors }}
            </li>
            <li class="p-1 d-block">
              <strong>Files(s)</strong>
              <div
                v-for="(file, fileIndex) in ver.userTypeData.investor.files"
                :key="`exp${fileIndex}`"
              >
                <a :href="file.fileUrl" target="_blank">{{ file.name }}</a>
              </div>
            </li>
            <li class="p-1 d-block">
              <strong>Interested Location(s)</strong>
              <div
                v-for="(location, locationIndex) in ver.userTypeData.investor.interstedLocations"
                :key="`exp${locationIndex}`"
              >
                <div>{{ location.name }}</div>
              </div>
            </li>

            <li class="p-1 d-block">
              <strong>Industrie(s)</strong>
              <div
                v-for="(ind, indIndex) in ver.userTypeData.investor.industries"
                :key="`exp${indIndex}`"
              >
                <div>{{ ind.name }}</div>
              </div>
            </li>
          </ul></b-card
        >
      </div>
      <!-- End Investor -->
    </template></b-sidebar
  >
</template>
<script>
import { BSidebar, BCard } from "bootstrap-vue";

export default {
  components: {
    BSidebar,
    BCard,
  },
  methods: {},
  props: {
    ver: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const handleChangeSidebar = (val) => {
      emit("update:show-details", val);
    };

    return {
      handleChangeSidebar,
    };
  },
};
</script>
